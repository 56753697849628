import React from "react";
import {Redirect} from "react-router-dom";
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";
import CloudUpload from "@material-ui/icons/CloudUpload";
// Core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";

import {connect} from "react-redux";
import AuthService from "customs/auth/AuthService";
import {doLogout} from "../../store/actions/authActions";

import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";
import { Base64 } from "js-base64";

import axios from "axios/axios";
import axiosHelper from "axios/axiosHelper";
import helpers from "customs/helpers/helpers";
import Button from "components/CustomButtons/Button.jsx";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import AddAlert from "@material-ui/icons/AddAlert";
import ConfirmDialog from "customs/components/ConfirmDialog";

import AccessControl from "customs/components/AccessControl";

class ASIC extends React.Component {

    constructor (props) {

        super(props);

        var url_string = window.location.href; 
        var url = new URL(url_string);
        var d = url.searchParams.get("d");
        var client_id = null, app_id = '';
        try {
            var params = JSON.parse(Base64.decode(d));
            client_id = params.hasOwnProperty('id') ? params.id : null;
            app_id = params.hasOwnProperty('app_id') ? params.app_id : '';
        } catch(e) {
            client_id = null;
            app_id = '';
        }

        this.state = {
            client_id: client_id,
            app_id: app_id,
            applications: undefined,
            pdf_file: null,
            "doneModalMsg": "",
            "tr_color": "success",
            "confirmModal": false,
            "confirmModalTitle": "",
            "confirmModalMsg": "Are you sure?",
            "action": ""
        };

        this.getApplications = this.getApplications.bind(this);
    }

    getApplications() {
        helpers.showLoading();
        axios(this.props)
            .get("/applications/list")
            .then((response) => {
                helpers.hideLoading();
                if (response.hasOwnProperty('data')) {
                    this.setState({"applications": response.data});
                } else {
                    this.setState({"applications": {}});
                }
            })
            .catch((error) => this.processErrorAxios(
                error,
                null
            ));
    }

    processErrorAxios(error, prop) {
        axiosHelper.processError(
            this.isUnmounted,
            prop,
            error,
            (state) => {
                this.setState(state);
            },
            () => {
                // this.showUsersNotification();
            }
        );
    }

    setUsersNotification (msg, color) {
        this.setState({"doneModalMsg": msg, "tr_color": color});
        this.showUsersNotification();
    }

    hideNotification;
    showUsersNotification () {
        if (!this.state.tr) {
            this.setState({"tr": true});
        } else {
            clearTimeout(this.hideNotification);
        }
        this.setHideNotificationTimeout();
    }

    setHideNotificationTimeout () {
        this.hideNotification = setTimeout(
            () => {
                this.handleCloseNotification();
            },
            this.state.tr_color === "success" ? 5000 : 10000
        );
    }

    handleCloseNotification () {
        clearTimeout(this.hideNotification);
        this.setState({"tr": false,
            "doneModalMsg": ""});
    }

    uploadPdf() {
        let cont = true;
        if (this.state.app_id.length === 0) {
            this.setUsersNotification(
                "Please select an application",
                "danger"
            );
            cont = false;
        } else if (this.state.pdf_file === null || this.state.pdf_file.length === 0) {
            this.setUsersNotification(
                "Please select a PDF file to upload",
                "danger"
            );
            cont = false;
        }

        if (cont) {
            this.setState({
                "confirmModal": true,
                "confirmModalMsg": "Are you sure?",
                "confirmModalTitle": "Upload ASIC Search Result",
                "action": "upload_asic_search_result_pdf"
            });
        }
    }

    doUploadPdf() {
        this.setState({"confirmModal": false});

        const url = 'applications/' + this.state.app_id + '/asic';
        helpers.showLoading();

        let fd = new FormData();
        for (let i=0; i<this.state.pdf_file.length; i++) {
            const file = this.state.pdf_file[i];
            if (file.size > 4194304 || file.fileSize > 4194304) {
            } else {
                fd.append('file', file);
                break;
            }
        };

        return axios(this.props)
            .post(url, fd, {headers: {
                'Content-Type': 'multipart/form-data'
              }})
            .then(() => {
                this.setState({
                    "confirmModal": false,
                    "action": ""
                });
                this.setState({"app_id": '', "pdf_file": null});
                this.setUsersNotification("ASIC Search PDF uploaded.", "success");
                helpers.hideLoading();
            })
            .catch((error) => {
                this.processErrorAxios(error);
                helpers.hideLoading();
            });
    }
    
    handleConfirmClick () {
        if (this.state.action === "upload_asic_search_result_pdf") {
            this.doUploadPdf();
        }
    }

    handleClose (modal) {
        const x = [];
        x[modal] = false;
        this.setState(x);
    }

    render () {
        const authService = new AuthService(
            this.state,
            this.props
        );
        if (!AccessControl.checkRoutePermissions("/asic", this.props.auth)) {
            return <Redirect to="/admin/dashboard" />;
        }
        if (!authService.getToken()) {
            return <Redirect to="/auth/login" />;
        }
        if (authService.isPasswordExpired()) {
            return <Redirect to="/admin/changepassword" />;
        }
        const {classes} = this.props;

        if (this.state.applications === undefined) {
            setTimeout(() => {this.setState({"applications": null}); this.getApplications();}, 100);
        }

        return (
            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader
                            color="success"
                            icon
                        >
                            <CardIcon color="success">
                                <CloudUpload />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>
                                ASIC Upload
                            </h4>
                        </CardHeader>
                        <CardBody>
                            <FormControl
                                className={classes.selectFormControl}
                                fullWidth
                            >
                                {
                                    (this.state.applications !== undefined && this.state.applications !== null && this.state.applications.length > 0)
                                    ? <b>Applications</b>
                                    : (this.state.applications === null ? 'Loading applications' : 'No applications available')
                                }
                                {
                                    (this.state.applications !== undefined && this.state.applications !== null && this.state.applications.length > 0)
                                    ? <Select
                                        classes={{"select": classes.select}}
                                        inputProps={{
                                            "name": `app_id`,
                                            "id": `app_id`
                                        }}
                                        MenuProps={{"className": classes.selectMenu}}
                                        onChange={(event) => {
                                            this.setState({'app_id': event.target.value});
                                        }}
                                        value={this.state.app_id}
                                    >
                                        <MenuItem
                                            classes={{"root": classes.selectMenuItem}}
                                            disabled
                                        >
                                            Choose an application
                                        </MenuItem>
                                        {
                                            this.state.applications.map((prop, key) => {
                                                return <MenuItem
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                        selected: classes.selectMenuItemSelectedMultiple
                                                    }}
                                                    key={"app_id_" + key}
                                                    value={prop.id}
                                                >
                                                    { 'Application ID: ' + prop.id + ' (ABN: ' + prop.abn + ')' }
                                                </MenuItem>;
                                            })
                                        }
                                    </Select>
                                    : ''
                                }
                            </FormControl>
                            <div
                                className="upload-btn-wrapper"
                                style={{"width": "100%"}}
                            >
                                <button
                                    className="btn"
                                    style={{"marginRight": "10px"}}
                                >
                                    <i className="fas fa-upload"></i> Choose a PDF file to upload
                                </button>
                                <input
                                    accept=".pdf"
                                    name="pdf_file"
                                    id="pdf_file"
                                    onChange={(event) => {
                                        let attachments = [];
                                        let errored_files = [];
                                        try {
                                            event.target.files.forEach(file => {
                                                if (Math.round(file.size / 1024) >= 4096) {
                                                    errored_files.push(file);
                                                } else {
                                                    attachments.push(file);
                                                }
                                            });
                                        } catch (e) {}

                                        if (errored_files.length > 0) {
                                            this.setUsersNotification(
                                                "The selected file exceeds the limit of 4MB per file and will not be uploaded",
                                                "danger"
                                            );
                                        }

                                        this.setState({"pdf_file" : attachments});
                                    }}
                                    type="file"
                                    value=""
                                />
                            </div>
                            <div style={{display: this.state.pdf_file !== null && this.state.pdf_file.length > 0 ? "" : "none"}}>
                                <p
                                    htmlFor="document"
                                    style={{"color": "#495057"}}
                                >
                                    {this.state.pdf_file !== null && this.state.pdf_file.length > 0
                                        ? helpers.addLineBreaks(this.state.pdf_file.map((file, k) => {
                                            return file.name.toString();
                                        }).join("<br>"))
                                        : ""}
                                    <span style={{cursor: "pointer"}} onClick={() => {this.setState({pdf_file: null})}}>[Clear Selected File]</span>
                                </p>
                            </div>

                            <div
                                className="upload-btn-wrapper"
                                style={{"width": "100%", "paddingTop": "2rem"}}
                            >
                                <Button
                                        className={classes.modalSmallFooterFirstButton}
                                        color="success"
                                        onClick={() => this.uploadPdf()}
                                    >
                                        Upload
                                </Button>
                            </div>
                        </CardBody>
                    </Card>
                </GridItem>
                
                {
                    this.state.doneModalMsg
                    ? <Snackbar
                        close
                        closeNotification={() => {
                            this.handleCloseNotification();
                        }}
                        color={this.state.tr_color}
                        icon={AddAlert}
                        message={this.state.doneModalMsg}
                        open={this.state.tr}
                        place="tr"
                    />
                    : ""
                }
                <ConfirmDialog
                    confirmModal={this.state.confirmModal}
                    confirmModalMsg={this.state.confirmModalMsg}
                    confirmModalTitle={this.state.confirmModalTitle}
                    onClose={() => this.handleClose("confirmModal")}
                    onYesClick={() => this.handleConfirmClick()}
                />
            </GridContainer>
        );
    }
}

ASIC.propTypes = {
    "classes": PropTypes.object
};

const mapStateToProps = (state, props) => ({
    "auth": state.auth.data
}),

mapDispatchToProps = (dispatch) => ({
    "doLogout": (props) => dispatch(doLogout(props))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(extendedFormsStyle)(ASIC));
