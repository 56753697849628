import React from "react";
import {Redirect} from "react-router-dom";
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import ReactTable from "react-table";
import AccountBox from "@material-ui/icons/AccountBox";
import Refresh from "@material-ui/icons/Refresh";
import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
// import Close from "@material-ui/icons/Close";

// Core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Pagination from "customs/components/Pagination";
import Overview from "customs/components/Overview";
import ConfirmDialog from "customs/components/ConfirmDialog";
import UserDetailsDialog from "customs/components/UserDetailsDialog";
import ClientDetailsDialog from "customs/components/ClientDetailsDialog";
import ClientFullDetailsDialog from "customs/components/ClientFullDetailsDialog";
import AddManagerToClientDialog from "customs/components/AddManagerToClientDialog";
import SelectScriptTemplates from "customs/components/SelectScriptTemplates";
import DuplicateClientScripts from "customs/components/DuplicateClientScripts";

import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "components/Snackbar/Snackbar.jsx";

import {connect} from "react-redux";
import AuthService from "customs/auth/AuthService";
import AccessControl from "customs/components/AccessControl";
import helpers from "customs/helpers/helpers";
import validations from "customs/helpers/validations";
import {doLogout} from "../../store/actions/authActions";

import axios from "axios/axios";

import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";
import axiosHelper from "axios/axiosHelper";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import {InputAdornment} from "@material-ui/core";
// import PublicIcon from "@material-ui/icons/Public";
import Icon from '@material-ui/core/Icon';
import _ from 'lodash';

class Clients extends React.Component {

    constructor (props) {

        super(props);
        this.state = {
            "selected_form": {
                "client_forms": [],
                "forms_schema": [],
                "acquirers": []
            },
            "formOptions": [],
            "from": "",
            "clients": [],
            "managers": [],
            "manager_teams": [],
            "leads": [],
            "transaction_methods": [],
            "script_templates": [],
            "merchant_types": [],
            "mccs": [],
            "client_selected": null,
            "saved_data": null,
            "states": null,
            "countries": null,
            "loading": false,
            "pages": 0,
            "roles": [],
            "confirmModal": false,
            "confirmModalTitle": "",
            "confirmModalMsg": "Are you sure?",
            "action": "",
            "sub_action": "",
            "approval_file": null,
            "delete_id": "",
            "clientDetailsModal": false,
            "id": "",
            "username": "",
            "password": "",
            // "abn": "",
            "company": "",
            "referrer": "",
            "user_roles": [],
            "username_valid": "",
            "password_valid": "",
            "primary_contact_id": "",
            "contacts": [],
            "company_valid": "",
            "referrer_valid": "",
            "managers_valid": "",
            "client_transaction_methods": [],
            "transaction_methods_valid": "",
            "client_managers": [],
            "client_manager_team": "",
            "client_manager_team_valid": "",
            "primary_contact_first_name_valid": "",
            "primary_contact_last_name_valid": "",
            "lead_operator_id": "",
            "merchant_type_id": "",
            "mcc_code": "",
            "userDetailsModal": false,
            "selectManagerModal": false,
            "selectScriptTemplateModal": false,
            "selected_templates": [],
            "from_client_id": "",
            "duplicateClientScriptsModal": false,
            "clientsList": [],
            "clientScripts": [],
            "available_managers": [],
            "doneModal": false,
            "doneModalMsg": "",
            "doneModalBtn": "",
            "btnDisabled": false,
            "tr": false,
            "tr_color": "success",
            "current_client_tab": 0,
            "selected_script": -1,
            "client_primary_contact": [],
            "contact_documents": [],
            "show_archived": 0,
            "client_read_only": AccessControl.checkRoutePermissions("/clients", this.props.auth, "read_only"),
            "generate_client_script": AccessControl.checkRoutePermissions("/clients", this.props.auth, "generate_script"),
            "document_library": [],
            "selected_documents": [],
            "open_client_script": props.location && props.location.state && props.location.state.script && props.location.state.from && props.location.state.from === "dashboard"
                ? props.location.state.script : {},
            "page": props.location && props.location.state && props.location.state.script && props.location.state.script.page ? props.location.state.script.page - 1 : 0,
            filtered: [],
            sorted: [],
            pageSize: 10
        };
        // if (props.location && props.location.state) {
        //     if (props.location.state.from === "abn") {
        //         this.state.from = props.location.state.from;
        //         this.state.clientDetailsModal = props.location.state.clientDetailsModal;
        //         this.state.abn = props.location.state.abn;
        //         this.state.company = props.location.state.company;
        //         this.state.action = "create_client";
        //     }
        // }

        this.handleChange = this.handleChange.bind(this);
        this.handleManualChange = this.handleManualChange.bind(this);
        this.handleChangeByName = this.handleChangeByName.bind(this);
        this.handleDeleteClientManager = this.handleDeleteClientManager.bind(this);
        this.handleAddClientManager = this.handleAddClientManager.bind(this);
        this.handleAddManagerFromAddDialog = this.handleAddManagerFromAddDialog.bind(this);
        this.handleAddNewManager = this.handleAddNewManager.bind(this);
        this.getFormOptions = this.getFormOptions.bind(this);

        this.refreshClientContactsTable = this.refreshClientContactsTable.bind(this);
        this.handleDeleteContact = this.handleDeleteContact.bind(this);
        this.handleDeleteNote = this.handleDeleteNote.bind(this);
        this.handleDeleteFile = this.handleDeleteFile.bind(this);
        this.handleDownloadContactDocument = this.handleDownloadContactDocument.bind(this);
        this.handleSaveContactDetails = this.handleSaveContactDetails.bind(this);
        this.handleSaveContactError = this.handleSaveContactError.bind(this);
        this.changeClientTabCallBack = this.changeClientTabCallBack.bind(this);

        this.setUsersNotification = this.setUsersNotification.bind(this);
        this.handleViewForms = this.handleViewForms.bind(this);
        this.handleAddForm = this.handleAddForm.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.handleFormSubmitForApproval = this.handleFormSubmitForApproval.bind(this);
        this.handleChangePrimaryContact = this.handleChangePrimaryContact.bind(this);
        this.handleUpdateStep = this.handleUpdateStep.bind(this);
        this.handleGenerateScript = this.handleGenerateScript.bind(this);
        this.handleRemoveLibraryDocument = this.handleRemoveLibraryDocument.bind(this);
        this.handleAddDocuments = this.handleAddDocuments.bind(this);
        this.generateScriptDialog = this.generateScriptDialog.bind(this);
        this.handleResetPassword = this.handleResetPassword.bind(this);
        this.doHandleResetPassword = this.doHandleResetPassword.bind(this);
        this.handleUpdateWatch = this.handleUpdateWatch.bind(this);
        this.scrollToClientDetails = this.scrollToClientDetails.bind(this);
        this.handleApplicationSignature = this.handleApplicationSignature.bind(this);
        this.showDuplicateScriptDialog = this.showDuplicateScriptDialog.bind(this);
        this.handleDuplicateScriptClientSelect = this.handleDuplicateScriptClientSelect.bind(this);
        this.handleDuplicateScripts = this.handleDuplicateScripts.bind(this);

        this.clientFullDetailsRef = React.createRef();
    }

    handleClose (modal) {
        const x = [];
        x[modal] = false;
        x['reset_client_password'] = "";
        this.setState(x);
    }

    handleConfirmClick () {
        if (this.state.action === "create_client" || this.state.action === "update_client") {
            if (this.state.sub_action === "reset_client_password") {
                this.doHandleResetPassword();
            } else {
                this.doSaveUserDetailsClick();
            }
        } else if (this.state.action === "delete_client") {
            this.handleDeleteClient(this.state.delete_id);
        } else if (this.state.action === "create_manager") {
            this.doSaveNewManagerClick();
        } else if (this.state.action === "delete_contact") {
            this.doHandleDeleteContact();
        } else if (this.state.action === "client_contact") {
            this.doHandleSaveContact();
        } else if (this.state.action === "delete_note") {
            this.doHandleDeleteNote();
        } else if (this.state.action === "delete_file") {
            this.doHandleDeleteFile();
        } else if (this.state.action === "save_client_application") {
            this.doHandleFormSubmit();
        } else if (this.state.action === "submit_client_application") {
            this.doHandleFormSubmitForApproval();
        } else if (this.state.action === "change_primary_contact") {
            this.doHandleChangePrimaryContact();
        } else if (this.state.action === "generate_script") {
            this.doHandleGenerateScript();
        } else if (this.state.action === "restore_client") {
            this.handleRestoreClient(this.state.delete_id);
        } else if (this.state.action === "remove_document") {
            this.doHandleRemoveLibraryDocument();
        } else if (this.state.action === "add_document") {
            this.doHandleAddDocuments();
        } else if (this.state.action === "submit_for_signing") {
            this.sendApplicationSignatureRequest(this.state.approval_file);
        } else if (this.state.action === "duplicate_script") {
            this.doHandleDuplicateScripts();
        }
    }

    getClientsData (page, pageSize, sorted, filtered, handleRetrievedData) {
        const url = "/clients",
            postObject = {
                "page": page + 1,
                "per_page": pageSize,
                "sort": sorted,
                "filter": filtered,
                "available_managers": [],
                "show_archived": this.state.show_archived
            };

        return axios(this.props)
            .post(
                url,
                postObject
            )
            .then((response) => {

                handleRetrievedData(response);
                this.getCountriesData();

            })
            .catch((error) => {

                this.processErrorAxios(
                    error,
                    null
                );
                this.getCountriesData();

            });
    }

  handleAddForm = (client_id, form_id) => {
      helpers.showLoading();
      axios(this.props)
        .post(`/forms/add/${client_id}/${form_id}`)
        .then((response) => {
              this.handleViewForms(client_id, false, true);
          })
          .catch((error) => {

              this.processErrorAxios(
                  error,
                  null
              );

          });
  };

  handleViewForms = (client_id, show_saved_message, show_loading) => {
      if (show_loading) helpers.showLoading();
      axios(this.props)
        .get(`/forms/client/${client_id}`)
        .then((response) => {
              this.setState({"selected_form": response.data});
              if (show_saved_message) {
                this.setState({
                    "doneModalMsg": "Application saved",
                    "tr_color": "success"
                });
                this.showUsersNotification();
              }
              if (show_loading) helpers.hideLoading();
          })
          .catch((error) => this.processErrorAxios(
              error,
              null
          ));
  };

  processClientsData (res) {
      if (this.isUnmounted) {
          return;
      }
      let {client_selected, open_client_script} = this.state;
      if (open_client_script && Object.keys(open_client_script).length > 0) {
        client_selected = null;
      }
      let client = null, current_client_tab = this.state.current_client_tab, selected_script = this.state.selected_script;
      let clients = res.data.data.map((prop, key) => {
        if (
            (client_selected && client_selected.id && client_selected.id === prop.id) ||
            (open_client_script && Object.keys(open_client_script).length > 0 && open_client_script.client_id && open_client_script.client_id === prop.id)
        ) {
            let client_scripts = null;
            if (prop.hasOwnProperty('scripts') && Object.keys(prop.scripts).length > 0) {
                client_scripts = prop.scripts;
            } else if (prop.hasOwnProperty('list_scripts') && Object.keys(prop.list_scripts).length > 0) {
                client_scripts = prop.list_scripts;
            }
            if (client_scripts !== null) {
                for(let key in client_scripts) {
                    if (client_scripts[key].id === open_client_script.id) {
                        this.scrollToClientDetails();
                        selected_script = parseInt(key);
                        break;
                    }
                }
            }
            client = prop;
            current_client_tab = open_client_script && Object.keys(open_client_script).length > 0 ? 2 : this.state.current_client_tab;
        }
        return this.setClientDetails(
            prop,
            key
        );
      });

      this.setState({
        "roles": res.data.roles,
        "managers": res.data.managers,
        "manager_teams": res.data.manager_teams,
        "leads": res.data.leads,
        "transaction_methods": res.data.transaction_methods,
        "script_templates": res.data.script_templates,
        "merchant_types": res.data.merchant_types,
        "mccs": res.data.mccs,
        "document_library": res.data.document_library,
        "clients": clients,
        "pages": res.data.last_page,
        "items_data": res.data.from && res.data.to && res.data.total ? `${res.data.from} - ${res.data.to} of ${res.data.total}` : "",
        "loading": false,
        "open_client_script": {},
        "client_selected": client,
        "current_client_tab": selected_script === -1 ? 0 : current_client_tab,
        "selected_script": selected_script
      });
      this.props.updateSelectedClient({...this.props}, client ? client.id : null);

      if (client !== null && (!client.hasOwnProperty('script') || !client.hasOwnProperty('registry_entries'))) {
          this.refreshClientContactsTable(null, client.id);
      }
  }

  getCountriesData () {
      if (this.state.countries === null) {
          axios(this.props)
            .get("/countries")
            .then((response) => this.processCountriesData(response))
            .catch((error) => this.processErrorAxios(
                  error,
                  null
              ));
      }
  }

  processCountriesData (res) {
      if (this.isUnmounted) {
          return;
      }
      this.setState({"countries": res.data.countries});
  }

    generateScriptDialog(id, username) {
        if (this.isUnmounted) {
            return;
        }

        helpers.showLoading();
        axios(this.props)
            .post(`/client/${id}/scripts`, {}, {})
            .then((response) => {
                this.setState({
                    "selectScriptTemplateModal": true,
                    "username": username,
                    "client_id_script": id,
                    "script_templates": response.data
                });
                helpers.hideLoading();
            })
            .catch((error) => this.processErrorAxios(
                error,
                null
            ));
    }

    handleGenerateScript(templates) {
        if (Object.keys(templates).length === 0 || templates.length === 0) {
            this.setState({
                "btnDisabled": false,
                "doneModal": false,
                "doneModalMsg": "Please select at least one script template.",
                "doneModalBtn": "OK",
                "userDetailsModal": false,
                "tr_color": "danger"
            });
            this.showUsersNotification();
            return;
        }

        this.setState({
            "confirmModal": true,
            "confirmModalMsg": `Are you sure you want to generate scripts for this client?`,
            "confirmModalTitle": "Generate Script",
            "action": "generate_script",
            "selected_templates": templates
        });
    }

    doHandleGenerateScript() {
        this.setState({"confirmModal": false, "action": "generate_script", "selectScriptTemplateModal": false});
        helpers.showLoading();
        this.setState({"btnDisabled": true});

        axios(this.props)
            .post(
                `/client/${this.state.client_id_script}/generate/script`,
                {"templates": this.state.selected_templates},
                {}
            )
            .then((response) => this.processGenerateScript(response))
            .catch((error) => this.processErrorAxios(
                error,
                null
            ));
    }

    processGenerateScript (results) {
        if (this.isUnmounted) {
            return;
        }
        this.setState({
            "btnDisabled": false,
            "client_id_script": "",
            "doneModalMsg": "Client script generated",
            "selected_templates": [],
            "tr_color": "success"
        });
        this.refreshClientsTable();
        this.showUsersNotification();
        helpers.hideLoading();
    }

    showDuplicateScriptDialog() {
        helpers.showLoading();
        axios(this.props)
        .get(`/client/all`)
            .then((response) => this.processGetAllClients(response))
            .catch((error) => this.processErrorAxios(
                error,
                null
            ));
    }

    processGetAllClients(response) {
        if (this.isUnmounted) {
            return;
        }
        this.setState({
            "btnDisabled": false,
            "duplicateClientScriptsModal": true,
            "clientsList": response.data,
            "selected_templates": [],
            "from_client_id": "",
            "clientScripts": []
        });
        helpers.hideLoading();
    }

    handleDuplicateScriptClientSelect(id) {
        helpers.showLoading();
        axios(this.props)
        .get('/client/' + id + '/scripts/list')
            .then((response) => this.processDuplicateScriptClientSelect(response))
            .catch((error) => this.processErrorAxios(
                error,
                null
            ));
    }

    processDuplicateScriptClientSelect(response) {
        if (this.isUnmounted) {
            return;
        }
        this.setState({
            "clientScripts": response.data.scripts
        });
        helpers.hideLoading();
    }

    handleDuplicateScripts(from_client_id, templates) {
        if (from_client_id === null || from_client_id.length === 0) {
            this.setState({
                "btnDisabled": false,
                "doneModal": false,
                "doneModalMsg": "Please select a client.",
                "doneModalBtn": "OK",
                "userDetailsModal": false,
                "tr_color": "danger"
            });
            this.showUsersNotification();
            return;
        }
        if (Object.keys(templates).length === 0 || templates.length === 0) {
            this.setState({
                "btnDisabled": false,
                "doneModal": false,
                "doneModalMsg": "Please select at least one script to duplicate.",
                "doneModalBtn": "OK",
                "userDetailsModal": false,
                "tr_color": "danger"
            });
            this.showUsersNotification();
            return;
        }

        this.setState({
            "confirmModal": true,
            "confirmModalMsg": `Are you sure you want to duplicate this client's script(s)? Existing script values will be overwritten!`,
            "confirmModalTitle": "Duplicate Script",
            "action": "duplicate_script",
            "selected_templates": templates,
            "from_client_id": from_client_id
        });
    }

    doHandleDuplicateScripts() {
        this.setState({"confirmModal": false, "action": "duplicate_script", "duplicateClientScriptsModal": false});
        helpers.showLoading();
        this.setState({"btnDisabled": true});

        axios(this.props)
            .post(
                `client/${this.state.client_id_script}/duplicate/${this.state.from_client_id}/scripts`,
                {"script_template_id": this.state.selected_templates},
                {}
            )
            .then((response) => this.processDuplicateScripts(response))
            .catch((error) => this.processErrorAxios(
                error,
                null
            ));
    }

    processDuplicateScripts (results) {
        if (this.isUnmounted) {
            return;
        }
        this.setState({
            "btnDisabled": false,
            "client_id_script": "",
            "doneModalMsg": "Client script duplicated",
            "selected_templates": [],
            "from_client_id": "",
            "tr_color": "success"
        });
        this.refreshClientsTable();
        this.showUsersNotification();
        helpers.hideLoading();
    }

    handleAddDocuments(documents, callback) {
        if (documents.length === 0) {
            this.setState({
                "btnDisabled": false,
                "doneModal": false,
                "doneModalMsg": "Please select at least one document.",
                "doneModalBtn": "OK",
                "tr_color": "danger"
            });
            this.showUsersNotification();
            return;
        }

        this.setState({
            "confirmModal": true,
            "confirmModalMsg": `Are you sure you want to add selected document(s) to this client?`,
            "confirmModalTitle": "Add Documents",
            "action": "add_document",
            "selected_documents": documents,
            "add_document_callback": callback
        });
    }

    doHandleAddDocuments() {
        this.setState({"confirmModal": false, "action": "add_document"});
        helpers.showLoading();
        this.setState({"btnDisabled": true});

        axios(this.props)
            .post(
                `/client/${this.state.client_selected.id}/document_library/add`,
                {"documents": this.state.selected_documents},
                {}
            )
            .then((response) => {
                if (this.isUnmounted) {return;}

                helpers.hideLoading();
                if (this.state.add_document_callback) {
                    this.state.add_document_callback();
                }
                this.setState({
                    "btnDisabled": false,
                    "selected_documents": [],
                    "doneModalMsg": "Document(s) added to client",
                    "tr_color": "success"
                });
                this.showUsersNotification();
            })
            .catch((error) => this.processErrorAxios(
                error,
                null
            ));
    }

  setClientDetails (prop, key) {
      if (this.state.client_read_only) {
            return {
                "id": prop.id,
                key,
                "username": prop.username,
                "company": prop.company,
                "referrer": prop.referrer,
                "data": prop
            }
      }

      return {
          "id": prop.id,
          key,
          "username": prop.username,
          "company": prop.company,
          "referrer": prop.referrer,
          "data": prop,
          "actions":
            <div>
                <Overview
                    title={'Show client overview'}
                    getClients={() => {

                        const obj = this.state.clients.find((o) => o.key === key);
                        return obj;

                    }}
                />
                {" "}
                {prop.deleted_at === null
                    ? <Button
                        title={'Update client details'}
                        className="edit"
                        color="info"
                        justIcon
                        onClick={() => {

                            const obj = this.state.clients.find((o) => o.key === key);
                            this.handleClientDetailsClick(
                                "clientDetailsModal",
                                obj.data
                            );

                        }}
                        round
                        simple
                    >
                        <Edit />
                    </Button>
                    :""}
                {" "}
                {prop.deleted_at === null && this.state.generate_client_script
                    ? <Button
                        title={'Generate Script'}
                        className="info"
                        color="success"
                        justIcon
                        onClick={() => {
                            const obj = this.state.clients.find((o) => o.key === key);
                            this.generateScriptDialog(obj.data.id, obj.data.username);
                        }}
                        round
                        simple
                    >
                        <Icon className="fas fa-list-ul" />
                    </Button>
                    : ""}
                {" "}
                {prop.deleted_at === null && this.accessControlManager
                    ? <Button
                        title={'Archive client'}
                        className="remove"
                        color="warning"
                        justIcon
                        onClick={() => {

                            const obj = this.state.clients.find((o) => o.key === key);
                            this.handleClientDetailsDeleteClick(
                                obj.data.id,
                                obj.data.username
                            );

                        }}
                        round
                        simple
                    >
                        <Icon className="fas fa-archive" />
                    </Button>
                    : (this.accessControlManager ? <Button
                        title={'Restore client'}
                        className="remove"
                        color="danger"
                        justIcon
                        onClick={() => {

                            const obj = this.state.clients.find((o) => o.key === key);
                            this.handleClientDetailsRestoreClick(
                                obj.data.id,
                                obj.data.username
                            );

                        }}
                        round
                        simple
                    >
                        <Icon className="fas fa-trash-restore" />
                    </Button> : "")}
                {" "}
            </div>
      };
  }

  refreshClientsTable () {
    if (this.isUnmounted) {
        return;
    }
      this.refReactClientsTable.fireFetchData();
  }

  handleClientLedgerClick (data) {
    if (!data.hasOwnProperty('registry_entries')) {
        data['registry_entries'] = [];
    }
    if (!data.hasOwnProperty('scripts')) {
        data['scripts'] = [];
    }
    this.props.updateSelectedClient({...this.props}, data ? data.id : null);
    this.scrollToClientDetails();
      this.setState({"client_selected": data});
  }

  handleClientOverviewClick () {}

  handleClientDetailsClick (modal, data) {
    if (this.state.countries === null) return;

      const clientDetails = data !== null
          ? {
              "id": data.id ? data.id : "",
              "username": data.username ? data.username : "",
              "password": "",
              "company": data.company ? data.company : "",
              "referrer": data.referrer ? data.referrer : "",
              "client_managers": data.managers
                  ? data.managers.map((prop, key) => prop.id)
                  : [],
              "client_manager_team": data.manager_team_id
                  ? data.manager_team_id
                  : "",
              "lead_operator_id":  data.lead_operator_id
                ? data.lead_operator_id
                : "",
            "merchant_type_id":  data.merchant_type_id
                ? data.merchant_type_id
                : "",
            "mcc_code":  data.mcc_code
                ? data.mcc_code
                : "",
            //   "abn": data.abn ? data.abn : "",
            //   "abn_valid": "",
              "username_valid": "",
              "password_valid": "",
              "company_valid": "",
              "referrer_valid": "",
              "managers_valid": "",
              "client_transaction_methods": data.transaction_methods ? data.transaction_methods.map((method) => method.id) : [],
              "transaction_methods_valid": "",
              "client_manager_team_valid": "",
              "primary_contact_first_name_valid": "",
              "primary_contact_last_name_valid": "",
              "primary_contact_id": data.primary_contact_id,
              "contacts": data.contacts,
              "action": "update_client",
              "confirmModalTitle": "Update Client Details"
          }
          : {
              "id": "",
              "username": "",
              "password": "",
              "company": "",
              "referrer": "",
              "client_managers": [],
              "client_manager_team": "",
              "lead_operator_id": "",
              "merchant_type_id": "",
              "mcc_code": "",
            //   "abn": "",
            //   "abn_valid": "",
              "username_valid": "",
              "password_valid": "",
              "company_valid": "",
              "referrer_valid": "",
              "managers_valid": "",
              "client_transaction_methods": [],
              "transaction_methods_valid": "",
              "client_manager_team_valid": "",
              "primary_contact_first_name_valid": "",
              "primary_contact_last_name_valid": "",
              "primary_contact_id": "",
              "contacts": [],
              "action": "create_client",
              "confirmModalTitle": "Create Client"
          },

        x = [];
        x[modal] = true;
        this.setState({...x, ...clientDetails});
  }

  handleClientDetailsDeleteClick (id, username) {
      if (id.length === 0) {
          return;
      }
      let name = username ? username : 'id ' + id;
      this.setState({
          "confirmModal": true,
          "confirmModalMsg": `Are you sure you want to archive ${name}?`,
          "confirmModalTitle": "Archive Client",
          "action": "delete_client",
          "delete_id": id
      });
  }

  handleClientDetailsRestoreClick (id, username) {
      if (id.length === 0) {
          return;
      }
      let name = username ? username : 'id ' + id;
      this.setState({
          "confirmModal": true,
          "confirmModalMsg": `Are you sure you want to restore ${name}?`,
          "confirmModalTitle": "Restore Client",
          "action": "restore_client",
          "delete_id": id
      });
  }

  handleChange (event) {
      this.setState({
          [event.target.id]: event.target.value,
          [`${event.target.id}_valid`]: ""
      });
  }

  handleManualChange (name, value) {
      this.setState({[name]: value, [`${name}_valid`]: ""});
  }

  handleChangeByName (event) {
    //   if (this.shouldPopulate(event)) {
    //       axios(this.props)
    //         .get(`/abn/${event.target.value}`)
    //         .then((response) => this.setState({
    //               "company": response.data.businessEntity.mainName.organisationName
    //           }))
    //           .catch((error) => console.log(error));
    //   }
      this.setState({
          [event.target.name]: event.target.value,
          [`${event.target.name}_valid`]: ""
      });
  }

//   shouldPopulate (event) {
//       return (
//           event.target.name === "abn" && validations.validateABN(event.target.value) && this.state.company === ""
//       );
//   }

  handleMultiple = (event) => {
      this.setState({"client_managers": event.target.value});
  };

  handleTransactionMethods = (values) => {
      this.setState({"client_transaction_methods": values});
  };

  handleSelectManagerTeam = (event) => {
      this.setState({"client_manager_team": event.target.value});
  };

  handleSelectLead = (event) => {
      this.setState({"lead_operator_id": event.target.value});
  };

  handleSelectPrimaryContact = (event) => {
      this.setState({"primary_contact_id": event.target.value});
  };

  handleSelectMerchantType = (event) => {
      this.setState({"merchant_type_id": event.target.value});
  };

  handleSelectMCCCode = (code) => {
      this.setState({"mcc_code": code});
  };

  handleAcceptClientDetails (modal, client_primary_contact, contact_documents) {
      const username_valid = true, //this.state.username.length > 0 ? validations.validateUsername(this.state.username) : true,
          password_valid = true,
        //   this.state.action === "update_client"
        //     ? true
        //     : validations.validatePassword(this.state.password),
          company_valid = this.state.company.length > 0 && this.state.company.length <= 255 && validations.validateBusinessName(this.state.company),
        //   abn_valid = validations.validateABN(this.state.abn) || validations.validateACN(this.state.abn),
          transaction_methods_valid = this.state.client_transaction_methods && this.state.client_transaction_methods.length > 0,
          client_manager_team_valid = (this.state.client_manager_team && this.state.client_manager_team.toString().length > 0)
                                    || (this.state.client_managers && this.state.client_managers.toString().length > 0),
          primary_contact_first_name_valid = client_primary_contact.first_name && client_primary_contact.first_name.length > 0,
          primary_contact_last_name_valid = client_primary_contact.last_name && client_primary_contact.last_name.length > 0,
          managers_valid = client_manager_team_valid,
          referrer_valid = true;
      if (
        username_valid &&
        password_valid &&
        company_valid &&
        referrer_valid &&
        managers_valid &&
        // abn_valid &&
        transaction_methods_valid &&
        client_manager_team_valid &&
        primary_contact_first_name_valid &&
        primary_contact_last_name_valid
      ) {
          const x = [];
          x[modal] = true;
          this.setState({
              ...x,
              "confirmModalMsg": "Are you sure?",
              "username_valid": "",
              "password_valid": "",
              "company_valid": "",
              "referrer_valid": "",
              "managers_valid": "",
              "transaction_methods_valid": "",
              "client_manager_team_valid": "",
              "primary_contact_first_name_valid": "",
              "primary_contact_last_name_valid": "",
            //   "abn_valid": "",
              "client_primary_contact": client_primary_contact,
              "contact_documents": contact_documents,
              "confirmModalTitle": this.state.action === 'update_client' ? "Update Client Details" : "Create Client",
              "sub_action": ""
          });
      } else {
          this.setState({
              "username_valid": username_valid ? "success" : "error",
              "password_valid": password_valid ? "success" : "error",
              "company_valid": company_valid ? "success" : "error",
              "referrer_valid": referrer_valid ? "success" : "error",
              "managers_valid": managers_valid ? "success" : "error",
            //   "abn_valid": abn_valid ? "success" : "error",
              "client_primary_contact": client_primary_contact,
              "transaction_methods_valid": transaction_methods_valid ? "success" : "error",
              "client_manager_team_valid": client_manager_team_valid ? "success" : "error",
              "primary_contact_first_name_valid": primary_contact_first_name_valid ? "success" : "error",
              "primary_contact_last_name_valid": primary_contact_last_name_valid ? "success" : "error",
              "contact_documents": contact_documents,
              "confirmModalTitle": this.state.action === 'update_client' ? "Update Client Details" : "Create Client",
              "sub_action": ""
          });
      }
  }

  doSaveUserDetailsClick () {
      this.handleClose("confirmModal");
      if (this.state.action === "update_client" && this.state.id.length === 0) {
      } else {
          helpers.showLoading();
          this.setState({"btnDisabled": true});

          let formData = {
            "id": this.state.id,
            "username": this.state.username,
            "password": this.state.password,
            // "abn": this.state.abn,
            "company": this.state.company,
            "referrer": this.state.referrer,
            "cm_user_ids": this.state.client_managers,
            "manager_team_id": this.state.client_manager_team,
            "lead_operator_id": this.state.lead_operator_id,
            "merchant_type_id": this.state.merchant_type_id,
            "mcc_code": this.state.mcc_code,
            "primary_contact": this.state.client_primary_contact,
            "transaction_methods": this.state.client_transaction_methods
        };
        let fd = new FormData();
        Object.keys(formData).forEach((key) => {
            fd = helpers.createFormData(
                fd,
                formData[key],
                key
            );
        });

        if (this.state.action === "update_client") {
            fd.append(
                "primary_contact_id",
                this.state.primary_contact_id
            );
            fd.append(
                "_method",
                "PATCH"
            );
        }
        axios(this.props)
            .post(
                "/client",
                fd,
                {
                    "headers": {"Content-Type": "application/x-www-form-urlencoded"}
                }
            )
            .then((response) => this.processSaveUserDetails(response, -1))
            .catch((error) => this.processErrorAxios(
                error,
                null
            ));
      }
  }

  processSaveUserDetails (results, idx) {
      if (this.isUnmounted) {
          return;
      }

    if (this.state.contact_documents === undefined || this.state.contact_documents.length === 0 || idx >= this.state.contact_documents.length - 1) {
        this.handleContactDetailsSaved();
    } else {
        let client = results.hasOwnProperty('data') && results.data.hasOwnProperty('client')
            ? results.data.client : (results.hasOwnProperty('data') ? results.data : results);
        this.doUploadDocuments(
            client.id,
            client.primary_contact_id,
            ++idx,
            client
        );
    }
  }

  doUploadDocuments (client_id, contact_id, idx, prev_result) {
    let fd = new FormData(),
        url = `/client/${client_id}/contact/${contact_id}/document`;
    if (
        this.state.contact_documents[idx].id &&
        this.state.contact_documents[idx].id !== undefined &&
        this.state.contact_documents[idx].id !== null
    ) {
        fd.append(
            "_method",
            "PUT"
        );
        fd.append(
            "id",
            this.state.contact_documents[idx].id
        );
        url = `${url}/${this.state.contact_documents[idx].id}`;
    }
    if (this.state.contact_documents[idx].file !== "") {
        fd.append(
            "file",
            this.state.contact_documents[idx].file
        );
    }
    if (this.state.contact_documents[idx].tags !== "") {
        fd.append(
            "tags",
            JSON.stringify(this.state.contact_documents[idx].tags)
        );
    }
    axios(this.props)
      .post(
            url,
            fd,
            {
                "headers": {"Content-Type": "application/x-www-form-urlencoded"}
            }
        )
        .then((response) => this.processSaveUserDetails(prev_result, idx))
        .catch((error) => this.processUploadErrorAxios(
            error,
            null,
            client_id,
            contact_id,
            idx,
            prev_result
        ));
  }

  processUploadErrorAxios (
    error,
    prop,
    client_id,
    contact_id,
    idx,
    prev_result
  ) {
    const default_err = helpers.getErrorMessage(error),
        saveDetailsErrors = default_err === false
          ? this.state.saveDetailsErrors
          : [
              ...this.state.saveDetailsErrors,
              default_err
          ];
    this.setState({saveDetailsErrors});
    if (this.state.contact_documents.length === 0 || idx >= this.state.contact_documents.length - 1) {
        this.handleContactDetailsSaved();
    } else {
        this.doUploadDocuments(
            client_id,
            contact_id,
            ++idx,
            prev_result
        );
    }
  }

  handleContactDetailsSaved() {
    this.refreshClientsTable();
    this.setState({
        "btnDisabled": false,
        "confirmModal": false,
        "clientDetailsModal": false,
        "doneModalMsg": "Client details saved",
        "tr_color": "success",
        "client_primary_contact": [],
        "contact_documents": []
    });
    this.showUsersNotification();
    helpers.hideLoading();
  }

  handleDeleteClient (id) {
      this.setState({
          "confirmModal": false,
          "action": "delete_client",
          "delete_id": ""
      });
      helpers.showLoading();
      const fd = new FormData();
      fd.append(
          "client_id",
          id
      );
      fd.append(
          "_method",
          "DELETE"
      );
      this.setState({"btnDisabled": true});

      axios(this.props)
        .post(
              "/client",
              fd,
              {
                  "headers": {"Content-Type": "application/x-www-form-urlencoded"}
              }
          )
          .then((response) => this.processDeleteClientDetails(
              response,
              id
          ))
          .catch((error) => this.processErrorAxios(
              error,
              null
          ));
  }

  processDeleteClientDetails (results, id) {
      if (this.isUnmounted) {
          return;
      }
      this.refreshClientsTable();
      let client = this.state.show_archived === 0 && this.state.client_selected && this.state.client_selected.id === id
        ? null : this.state.client_selected;
      this.setState({
          "btnDisabled": false,
          "client_selected": client,
          "doneModalMsg": "Client details archived",
          "tr_color": "success"
      });
      this.showUsersNotification();
      helpers.hideLoading();
      this.props.updateSelectedClient({...this.props}, client ? client.id : null);
  }
  
  handleRestoreClient (id) {
    this.setState({
        "confirmModal": false,
        "action": "restore_client",
        "delete_id": ""
    });
    helpers.showLoading();
    const fd = new FormData();
    fd.append(
        "client_id",
        id
    );
    this.setState({"btnDisabled": true});

    axios(this.props)
      .post(
            "/client/restore",
            fd,
            {
                "headers": {"Content-Type": "application/x-www-form-urlencoded"}
            }
        )
        .then((response) => this.processRestoreClientDetails(
            response,
            id
        ))
        .catch((error) => this.processErrorAxios(
            error,
            null
        ));
    }

    processRestoreClientDetails (results, id) {
        if (this.isUnmounted) {
            return;
        }
        this.refreshClientsTable();
        this.setState({
            "btnDisabled": false,
            "doneModalMsg": "Client details archived",
            "tr_color": "success"
        });
        this.showUsersNotification();
        helpers.hideLoading();
    }

  handleAddClientManager (event, id) {
      event.preventDefault();
      helpers.showLoading();
      axios(this.props)
        .get(`/get_available_managers/${id}`)
        .then((response) => this.processFetchAvailableCMs(response))
        .catch((error) => this.processErrorAxios(
              error,
              null
          ));
  }

  processFetchAvailableCMs (results) {
      if (this.isUnmounted) {
          return;
      }
      this.setState({
          "selectManagerModal": true,
          "available_managers": results.data
      });
      helpers.hideLoading();
  }

  handleAddManagerFromAddDialog (event, id) {
      event.preventDefault();
      this.handleAddManager(id);
  }

  handleAddManager (id) {
      helpers.showLoading();
      this.setState({"btnDisabled": true});
      axios(this.props)
        .post(
              "/assign_manager",
              {
                  "client_id": this.state.client_selected.id,
                  "cm_user_ids": [id]
              }
          )
          .then((response) => this.processAddManager(
              response,
              id
          ))
          .catch((error) => this.processErrorAxios(
              error,
              null
          ));
  }

  processAddManager (results, id) {
      if (this.isUnmounted) {
          return;
      }
      let {managers} = this.state.client_selected,
          {available_managers} = this.state;
      available_managers.find((o, i) => {
          if (o.id === id) {
              managers = [
                  ...managers,
                  o
              ];
              available_managers.splice(
                  i,
                  1
              );
              return true;
          }
          return false;
      });
      const {client_selected} = this.state;
      client_selected.managers = managers;
      this.updateClientsData(
          "managers",
          managers,
          this.state.client_selected.id
      );
      this.setState({
          "btnDisabled": false,
          client_selected,
          available_managers,
          "doneModalMsg": "Manager added to client",
          "tr_color": "success"
      });
      this.showUsersNotification();
      helpers.hideLoading();
  }

  updateClientsData (key, data, id) {
      const {clients} = this.state;
      try {
          clients[clients.findIndex((x) => x.id === id)][key] = data;
      } catch (e) {}
      this.setState({clients});
  }

  handleAddNewManager (event) {
      event.preventDefault();
      this.setState({
          "id": "",
          "username": "",
          "password": "",
          "company": "",
          "referrer": "",
          "client_managers": [],
          "client_manager_team": "",
          "lead_operator_id": "",
          "merchant_type_id": "",
          "mcc_code": "",
          "username_valid": "",
          "password_valid": "",
          "company_valid": "",
          "referrer_valid": "",
          "managers_valid": "",
          "action": "create_manager",
          "userDetailsModal": true
      });
  }

  handleAcceptAddNewManager () {
      const username_valid = validations.validateUsername(this.state.username),
          password_valid = this.state.action === "update"
            ? true
            : validations.validatePassword(this.state.password),
          company_valid = this.state.company.length > 0 && this.state.company.length <= 255;
      if (
        username_valid &&
        password_valid &&
        company_valid
      ) {
          this.setState({
              "confirmModal": true,
              "confirmModalTitle": "New Team Leader",
              "confirmModalMsg": "Are you sure?",
              "username_valid": "",
              "password_valid": "",
              "company_valid": ""
          });
      } else {
          this.setState({
              "username_valid": username_valid ? "success" : "error",
              "password_valid": password_valid ? "success" : "error",
              "company_valid": company_valid > 0 ? "success" : "error"
          });
      }
  }

  doSaveNewManagerClick () {
      this.handleClose("confirmModal");
      if (!this.state.client_selected) {
          this.setState({
              "btnDisabled": false,
              "doneModal": false,
              "doneModalMsg": "Page data has changed, please try again.",
              "doneModalBtn": "OK",
              "userDetailsModal": false,
              "tr_color": "danger"
          });
          this.showUsersNotification();
          return;
      }

      helpers.showLoading();
      this.setState({"btnDisabled": true});
      axios(this.props)
        .post(
              "/manager",
              {
                  "username": this.state.username,
                  "password": this.state.password,
                  "company": this.state.company,
                  "clients": [this.state.client_selected.id]
              }
          )
          .then((response) => this.processSaveNewManager(response))
          .catch((error) => this.processErrorAxios(error));
  }

  processSaveNewManager (results) {
      if (this.isUnmounted) {
          return;
      }
      const {client_selected} = this.state;
      client_selected.managers = [
          ...this.state.client_selected.managers,
          results.data.user
      ];
      const managers = [
          ...this.state.managers,
          results.data.user
      ];
      this.updateClientsData(
          "managers",
          managers,
          this.state.client_selected.id
      );
      this.setState({
          "btnDisabled": false,
          "userDetailsModal": false,
          client_selected,
          managers,
          "doneModal": false,
          "doneModalMsg":
        `Manager ${
            results.data.user.first_name
                ? `${results.data.user.first_name} `
                : ""
        }added to client`,
          "doneModalBtn": "OK",
          "tr_color": "success"
      });
      this.showUsersNotification();
      helpers.hideLoading();
  }

  handleDeleteClientManager (event, client_id, manager_id) {
      event.preventDefault();
      helpers.showLoading();
      this.setState({"btnDisabled": true});
      axios(this.props)
        .post(
              "/remove_manager",
              {
                  client_id,
                  "cm_user_ids": [manager_id]
              }
          )
          .then((response) => this.processDeleteManager(
              response,
              manager_id
          ))
          .catch((error) => this.processErrorAxios(
              error,
              null
          ));
  }

  processDeleteManager (results, id) {
      if (this.isUnmounted) {
          return;
      }
      const {managers} = this.state.client_selected;
      managers.find((o, i) => {
          if (o.id === id) {
              managers.splice(
                  i,
                  1
              );
              return true;
          }
          return false;
      });
      const {client_selected} = this.state;
      client_selected.managers = managers;
      this.updateClientsData(
          "managers",
          managers,
          this.state.client_selected.id
      );
      this.setState({
          "btnDisabled": false,
          client_selected,
          "doneModalMsg": "Manager removed from client",
          "tr_color": "success"
      });
      this.showUsersNotification();
      helpers.hideLoading();
  }

  getFormOptions () {
      axios(this.props)
        .get("/forms/list")
        .then((response) => this.setState({"formOptions": response.data.forms}))
        .catch((error) => this.processErrorAxios(
              error,
              null
          ));
  }

  fetchingClient = false;
  refreshClientContactsTable (event, client_id, callback) {
    if (this.isUnmounted) {
        return;
    }

      if (event !== null) {
          event.preventDefault();
      }
      if (this.fetchingClient) return;
      this.fetchingClient = true;

      helpers.showLoading();

      axios(this.props)
        .get(`/client/${client_id}`)
        .then((response) => {this.processRefreshClientContactsTable(
              response,
              callback ? callback : null
          ); this.fetchingClient = false;})
          .catch((error) => this.processErrorAxios(
              error,
              null
          ))
          .finally(() => this.fetchingClient = false);
  }

  processRefreshClientContactsTable (results, callback) {
      if (this.isUnmounted) {
          return;
      }
      this.setState({
          "btnDisabled": false,
          "clients": this.state.clients.map((prop, key) => {
              if (results.data.id === prop.id) {
                  prop.data = results.data;
              }
              if (
                this.state.client_selected &&
                this.state.client_selected.id &&
                this.state.client_selected.id === prop.id
              ) {
                  this.setState({"client_selected": prop.data});
              }
              return this.setClientDetails(
                  prop.data,
                  key
              );

          }),
          "contact": null
      });
      helpers.hideLoading();
      if (callback !== null) {
          callback();
      }
  }

  handleDeleteContact (event, client_id, contact_id, contact_name) {
      event.preventDefault();
      
      if (this.state.client_selected.primary_contact_id === contact_id) {
        this.setState({
            "doneModalMsg": "Primary contact cannot be deleted.",
            "tr_color": "danger"
        });
        this.showUsersNotification();
      } else {
        this.setState({
            "confirmModal": true,
            "confirmModalMsg": `Are you sure you want to delete ${contact_name}?`,
            "confirmModalTitle": "Delete Contact",
            "action": "delete_contact",
            "contact": {client_id,
                contact_id}
        });
        }
  }

  handleChangePrimaryContact (event, client_id, contact_id, contact_name) {
      event.preventDefault();
      
      if (this.state.client_selected.primary_contact_id === contact_id) {
      } else {
        this.setState({
            "confirmModal": true,
            "confirmModalMsg": `Are you sure you want to set ${contact_name} as the primary contact?`,
            "confirmModalTitle": "Set as Primary Contact",
            "action": "change_primary_contact",
            "contact": {client_id, contact_id}
        });
      }
  }
  
  handleFormSubmit(fd, client_form_id, form_field_count) {
    
    let form_fd = _.clone(fd);
    let count = helpers.countKeys(form_fd, true);
    form_fd['fields_count'] = form_field_count;
    form_fd['fields_filled_count'] = count;

    this.setState({
        "confirmModal": true,
        "confirmModalMsg": "Are you sure?",
        "confirmModalTitle": "Save Application",
        "action": "save_client_application",
        "form_fd": form_fd,
        "client_form_id": client_form_id
    });
  }

  doHandleFormSubmit () {
      this.setState({"confirmModal": false});
      helpers.showLoading();
      axios(this.props)
        .post(
              `forms/data/${this.state.client_form_id}`,
              this.state.form_fd
          )
          .then(() => {
            this.handleViewForms(this.state.client_selected.id, true, true);
            this.setState({"form_fd": "", "client_form_id": ""});
          })
          .catch((error) => this.processErrorAxios(
              error,
              null
          ));
  }

  handleFormSubmitForApproval (fd, client_form_id, id) {
    this.setState({
        "confirmModal": true,
        "confirmModalMsg": "Are you sure?",
        "confirmModalTitle": "Submit Application for Approval",
        "action": "submit_client_application",
        "form_fd": fd,
        "form_id": id,
        "client_form_id": client_form_id
    });
  }

  doHandleFormSubmitForApproval () {
      this.setState({"confirmModal": false});
      const app = {
          "form_data": this.state.form_fd,
          "client_form_id": this.state.client_form_id,
          "id": this.state.form_id
      };

      helpers.showLoading();
      axios(this.props)
        .post(
              "applications/submit",
              app
          )
          .then(() => {
            this.setState({
                "doneModalMsg": "Application submitted",
                "tr_color": "success"
            });
            this.showUsersNotification();
            this.setState({"form_fd": "", "client_form_id": "", "form_id": ""});
            helpers.hideLoading();
          })
          .catch((error) => {
              this.processErrorAxios(error, null);
              helpers.showLoading();
          });
  }

  doHandleDeleteContact () {
      this.setState({"confirmModal": false,
          "action": "delete_contact"});
      helpers.showLoading();
      const fd = new FormData();
      fd.append(
          "_method",
          "DELETE"
      );
      this.setState({"btnDisabled": true});

      axios(this.props)
        .post(
              `/client/${
                  this.state.contact.client_id
              }/contact/${
                  this.state.contact.contact_id}`,
              fd,
              {"headers": {"Content-Type": "application/x-www-form-urlencoded"}}
          )
          .then((response) => this.processDeleteContactDetails(response))
          .catch((error) => this.processErrorAxios(
              error,
              null
          ));
  }

  processDeleteContactDetails (results) {
      if (this.isUnmounted) {
          return;
      }
      this.setState({
          "btnDisabled": false,
          "clients": this.state.clients.map((prop, key) => {
              if (this.state.contact.client_id === prop.id) {
                  prop.data.contacts = prop.data.contacts.filter((contact) => contact.id !== this.state.contact.contact_id);
              }
              if (
                this.state.client_selected &&
                this.state.client_selected.id &&
                this.state.client_selected.id === prop.id
              ) {
                  this.setState({"client_selected": prop.data});
              }
              return this.setClientDetails(
                  prop.data,
                  key
              );

          }),
          "contact": null,
          "doneModalMsg": "Client contact deleted",
          "tr_color": "success"
      });
      this.showUsersNotification();
      helpers.hideLoading();
  }

  doHandleChangePrimaryContact () {
      this.setState({"confirmModal": false, "action": "change_primary_contact"});
      helpers.showLoading();
      this.setState({"btnDisabled": true});

      axios(this.props)
        .post(
              `/client/${this.state.contact.client_id}/contact/${this.state.contact.contact_id}/primary`,
              {},
              {}
          )
          .then((response) => this.processChangePrimaryContact(response, this.state.contact.contact_id))
          .catch((error) => this.processErrorAxios(
              error,
              null
          ));
  }

  processChangePrimaryContact (results, contact_id) {
      if (this.isUnmounted) {
          return;
      }
      helpers.showLoading();
      this.setState({
          "btnDisabled": false,
          "contact": null,
          "doneModalMsg": "Client primary contact updated",
          "tr_color": "success"
      });
      let client_selected = this.state.client_selected;
      client_selected.primary_contact_id = contact_id;
      this.setState({client_selected});
      this.refreshClientsTable();
      this.showUsersNotification();
    //   helpers.hideLoading();
  }

  handleDeleteNote (event, client_id, note_id, contact_name) {
      event.preventDefault();
      this.setState({
          "confirmModal": true,
          "confirmModalMsg": "Are you sure you want to delete this note?",
          "confirmModalTitle": "Delete Note",
          "action": "delete_note",
          "note": {client_id,
              note_id}
      });
  }

  doHandleDeleteNote () {
      this.setState({"confirmModal": false,
          "action": "delete_contact"});
      helpers.showLoading();
      const fd = new FormData();
      fd.append(
          "_method",
          "DELETE"
      );
      this.setState({"btnDisabled": true});

      axios(this.props)
        .post(
              `/client/${
                  this.state.note.client_id
              }/note/${
                  this.state.note.note_id}`,
              fd,
              {"headers": {"Content-Type": "application/x-www-form-urlencoded"}}
          )
          .then((response) => this.processDeleteNoteDetails(response))
          .catch((error) => this.processErrorAxios(
              error,
              null
          ));
  }

  processDeleteNoteDetails (results) {
      if (this.isUnmounted) {
          return;
      }
      this.setState({
          "btnDisabled": false,
          "clients": this.state.clients.map((prop, key) => {
              if (this.state.note.client_id === prop.id) {
                  prop.data.notes = prop.data.notes.filter((note) => note.id !== this.state.note.note_id);
              }
              if (
                this.state.client_selected &&
                this.state.client_selected.id &&
                this.state.client_selected.id === prop.id
              ) {
                  this.setState({"client_selected": prop.data});
              }
              return this.setClientDetails(
                  prop.data,
                  key
              );
          }),
          "note": null,
          "doneModalMsg": "Client note deleted",
          "tr_color": "success"
      });
      this.showUsersNotification();
      helpers.hideLoading();
  }

  handleDeleteFile (event, client_id, file_id) {
      event.preventDefault();
      this.setState({
          "confirmModal": true,
          "confirmModalMsg": "Are you sure you want to delete this file?",
          "confirmModalTitle": "Delete File",
          "action": "delete_file",
          "file": {client_id,
              file_id}
      });
  }

  doHandleDeleteFile () {
      this.setState({"confirmModal": false,
          "action": "delete_file"});
      helpers.showLoading();
      const fd = new FormData();
      fd.append(
          "_method",
          "DELETE"
      );
      this.setState({"btnDisabled": true});

      axios(this.props)
        .post(
              `/client/${
                  this.state.file.client_id
              }/file/${
                  this.state.file.file_id}`,
              fd,
              {"headers": {"Content-Type": "application/x-www-form-urlencoded"}}
          )
          .then((response) => this.processDeleteFileDetails(response))
          .catch((error) => this.processErrorAxios(
              error,
              null
          ));
  }

  processDeleteFileDetails (results) {
      if (this.isUnmounted) {
          return;
      }
      this.setState({
          "btnDisabled": false,
          "clients": this.state.clients.map((prop, key) => {
              if (this.state.file.client_id === prop.id) {
                  prop.data.files = prop.data.files.filter((file) => file.id !== this.state.file.file_id);
              }
              if (
                this.state.client_selected &&
                this.state.client_selected.id &&
                this.state.client_selected.id === prop.id
              ) {
                  this.setState({"client_selected": prop.data});
              }
              return this.setClientDetails(
                  prop.data,
                  key
              );

          }),
          "file": null,
          "doneModalMsg": "Client file deleted",
          "tr_color": "success"
      });
      this.showUsersNotification();
      helpers.hideLoading();
  }

  handleDownloadContactDocument (
      event,
      client_id,
      contact_id,
      document_id,
      file_name
  ) {

      event.preventDefault();
      const FileDownload = require("js-file-download");
      helpers.showLoading();
      axios(this.props)
        .get(
              `client/${
                  client_id
              }/contact/${
                  contact_id
              }/document/${
                  document_id}`,
              {"responseType": "blob",
                  "timeout": 30000}
          )
          .then((response) => {
            helpers.hideLoading();
            FileDownload(
                response.data,
                file_name
            );
          })
          .catch((error) => this.processErrorAxios(
              error,
              null
          ));
  }

  handleRemoveLibraryDocument(event, client_id, document_id) {
    event.preventDefault();
    this.setState({
        "confirmModal": true,
        "confirmModalMsg": "Are you sure you want to remove this document?",
        "confirmModalTitle": "Remove Document",
        "action": "remove_document",
        "file": {client_id, document_id}
    });
}

doHandleRemoveLibraryDocument () {
    this.setState({"confirmModal": false, "action": "remove_document"});
    helpers.showLoading();
    this.setState({"btnDisabled": true});

    axios(this.props)
      .post(
            `/document_library/${this.state.file.document_id}/clients/remove`,
            {'client_ids': [this.state.file.client_id]}
        )
        .then((response) => {
            if (this.isUnmounted) {return;}
            this.setState({
                "btnDisabled": false,
                "clients": this.state.clients.map((prop, key) => {
                    if (this.state.file.client_id === prop.id) {
                        prop.data.library_documents = prop.data.library_documents.filter((document) => document.id !== this.state.file.document_id);
                    }
                    if (
                      this.state.client_selected &&
                      this.state.client_selected.id &&
                      this.state.client_selected.id === prop.id
                    ) {
                        this.setState({"client_selected": prop.data});
                    }
                    return this.setClientDetails(
                        prop.data,
                        key
                    );
                }),
                "file": null,
                "doneModalMsg": "Client document removed",
                "tr_color": "success"
            });
            this.showUsersNotification();
            helpers.hideLoading();
        })
        .catch((error) => this.processErrorAxios(
            error,
            null
        ));
}

  handleSaveContactDetails (results, is_add, saveDetailsErrors) {
      if (this.isUnmounted) {
          return;
      }
      this.setState({
          "btnDisabled": false,
          "clients": this.state.clients.map((prop, key) => {
              if (results.data.contact.client_id === prop.id) {
                  if (is_add) {
                      prop.data.contacts.push(results.data.contact);
                  } else {
                      prop.data.contacts = prop.data.contacts.map((p, k) => p.id === results.data.contact.id
                        ? results.data.contact
                        : p);
                  }
              }
              if (
                this.state.client_selected &&
                this.state.client_selected.id &&
                this.state.client_selected.id === prop.id
              ) {
                  this.setState({"client_selected": prop.data});
              }
              return this.setClientDetails(
                  prop.data,
                  key
              );
          }),
          "doneModalMsg":
        `Client contact saved${
            saveDetailsErrors.length > 0
                ? `<br>${saveDetailsErrors.join("<br>")}`
                : ""}`,
          "tr_color": saveDetailsErrors.length > 0 ? "warning" : "success"
      });
      this.showUsersNotification();
      helpers.hideLoading();
  }

  handleSaveContactError (event) {
      event.preventDefault();
      this.setState({
          "doneModalMsg": "Please check contact fields with red labels!",
          "tr_color": "danger"
      });
      this.showUsersNotification();
  }

  processErrorAxios (error, prop) {
      axiosHelper.processError(
          this.isUnmounted,
          prop,
          error,
          (state) => {
              this.setState(state);
          },
          () => {
              this.showUsersNotification();
          },
          () => {
              this.props.doLogout({...this.props});
          }
      );

  }

  CustomTableFilter = ({filter, onChange}) => (<form>
          <TextField
              type="search"
              className="w-100"
              value={filter ? filter.value : ""}
              onChange={(event) => onChange(event.target.value)}
              InputProps={{
                  startAdornment: 
              <InputAdornment position="start">
                <SearchIcon fontSize="small" />
              </InputAdornment>
            
              }}
          />
       </form>)
    ;

  accessControlManager = AccessControl.checkRoutePermissions(
      "/clients",
      this.props.auth,
      "add_manager"
  );

  setColumns() {
    return [
        {
            "Header": "ID",
            "accessor": "id",
            "width": 70,
            "Filter": this.CustomTableFilter
        },
        {
            "Header": "Username",
            "accessor": "username",
            "Filter": this.CustomTableFilter
        },
        {
            "Header": "Company",
            "accessor": "company",
            "Filter": this.CustomTableFilter
        },
        {
            "Header": this.state.client_read_only ? "" : "Actions",
            "accessor": "actions",
            "sortable": false,
            "filterable": false,
            "width": this.state.client_read_only ? 20 : (this.accessControlManager ? 200 : 150)
        }
    ];
  }

  hideNotification;
  showUsersNotification () {
      if (!this.state.tr) {
          this.setState({"tr": true});
      } else {
          clearTimeout(this.hideNotification);
      }
      this.setHideNotificationTimeout();
  }

  setHideNotificationTimeout () {
      this.hideNotification = setTimeout(
          () => {
              this.handleCloseNotification();
          },
          this.state.tr_color === "success" ? 5000 : 10000
      );
  }

  handleCloseNotification () {
      clearTimeout(this.hideNotification);
      this.setState({"tr": false, "doneModalMsg": ""});
  }

  componentWillUnmount () {
      this.isUnmounted = true;
      clearTimeout(this.hideNotification);
  }

    componentWillReceiveProps (nextProps) {
        if (nextProps.location && nextProps.location.state && nextProps.location.state.script && nextProps.location.state.from && nextProps.location.state.from === "dashboard") {
            let open_client_script = nextProps.location.state.script;
            if (this.state.client_selected !== null && this.state.client_selected.id === nextProps.location.state.script.client_id) {
                // client is selected, update script
                let selected_script = -1;
                let client_scripts = null;
                if (this.state.client_selected.hasOwnProperty('scripts') && Object.keys(this.state.client_selected.scripts).length > 0) {
                    client_scripts = this.state.client_selected.scripts;
                } else if (this.state.client_selected.hasOwnProperty('list_scripts') && Object.keys(this.state.client_selected.list_scripts).length > 0) {
                    client_scripts = this.state.client_selected.list_scripts;
                }
                if (client_scripts !== null) {
                    for(let key in client_scripts) {
                        if (client_scripts[key].id === open_client_script.id) {
                            this.scrollToClientDetails();
                            selected_script = parseInt(key);
                            break;
                        }
                    }
                }
                try {
                    this.setState({"current_client_tab": 0});
                } catch(e) {}
                setTimeout(() => {
                    try {
                        this.setState({
                            "current_client_tab": selected_script === -1 ? 0 : (open_client_script && Object.keys(open_client_script).length > 0 ? 2 : this.state.current_client_tab),
                            "selected_script": selected_script
                        });
                    } catch(e) {}
                }, 50);
                setTimeout(() => this.refreshClientContactsTable(null, nextProps.location.state.script.client_id), 100);
            } else {
                let client_selected = this.state.clients.find((o) => o.id === nextProps.location.state.script.client_id);
                if (client_selected) { // client is not selected, but in current list
                    client_selected = client_selected.data;
                    let selected_script = -1;
                    let client_scripts = null;
                    if (client_selected.hasOwnProperty('scripts') && Object.keys(client_selected.scripts).length > 0) {
                        client_scripts = client_selected.scripts;
                    } else if (client_selected.hasOwnProperty('list_scripts') && Object.keys(client_selected.list_scripts).length > 0) {
                        client_scripts = client_selected.list_scripts;
                    }
                    if (client_scripts !== null) {
                        for(let key in client_scripts) {
                            if (client_scripts[key].id === open_client_script.id) {
                                this.scrollToClientDetails();
                                selected_script = parseInt(key);
                                break;
                            }
                        }
                    }
                    this.props.updateSelectedClient({...this.props}, client_selected ? client_selected.id : null);
                    try {
                        this.setState({
                            "current_client_tab": selected_script === -1 ? 0 : (open_client_script && Object.keys(open_client_script).length > 0 ? 2 : this.state.current_client_tab),
                            "selected_script": selected_script,
                            "client_selected": client_selected,
                        });
                    } catch(e) {}
                    setTimeout(() => this.refreshClientContactsTable(null, nextProps.location.state.script.client_id), 100);
                } else { // fetch new list
                    try {
                        this.setState({
                            "open_client_script": nextProps.location.state.script,
                            "page": nextProps.location.state.script.page - 1,
                            "filtered": [],
                            "sorted": [],
                            "show_archived": 0,
                            "pageSize": 10
                        });
                    } catch(e) {}
                    setTimeout(() => this.refreshClientsTable(), 100);
                }
            }
        }
    }

  changeClientTabCallBack (tab) {
      this.setState({"current_client_tab": tab});
  }

  setUsersNotification (msg, color) {
      this.setState({"doneModalMsg": msg, "tr_color": color});
      this.showUsersNotification();
  }

  saving_form = false;
    handleUpdateStep(id, client_script_id, data, callback, showLoading = true) {
        if (this.saving_form) return;
        if (data.form_data && Object.keys(data.form_data).length === 0) return;
        if (data.form_data && data.form_data.generate && data.form_data.generate === "credentials") {
            if (!this.state.client_selected.mailable && !this.state.client_selected.mobile_phone) {
                this.setState({
                    "doneModalMsg": "Please add an email address or a mobile phone number to this client's contact.",
                    "tr_color": "danger"
                });
                this.showUsersNotification();
                return ;
            }
        }
        this.saving_form = true;

        const url = "/client/" + this.state.client_selected.id + '/script/' + client_script_id + '/step/' + id;
        if (showLoading) {helpers.showLoading();}
        let fd = new FormData();
        Object.keys(data).forEach((key) => {
            fd = helpers.createFormData(
                fd,
                data[key],
                key
            );
        });
        if (!(Object.keys(Object.fromEntries(fd)).length > (0 + fd.has('form_count')))) {
            this.saving_form = false;
            try {if (callback !== null) callback();} catch(e) {}
            return;
        }
        return axios(this.props)
            .post(url, fd, {"headers": {"Content-Type": "application/x-www-form-urlencoded"}})
            .then((response) => {
                this.saving_form = false;
                let clients = this.state.clients.map((prop, key) => {
                    if (response.data.id === prop.id) {
                        return this.setClientDetails(response.data, key);
                    }
                    return prop;
                });
                let client = response.data;
                this.setState({
                    "clients": clients,
                    "client_selected": client,
                    "saved_data": data && data.form_data ? data.form_data : null
                });
                this.props.updateSelectedClient({...this.props}, client ? client.id : null);
                if (callback !== null) callback();
                if (data.form_data && data.form_data.generate && data.form_data.generate === "applications") {
                    this.handleViewForms(response.data.id, false, true);
                } else {
                    helpers.hideLoading();

                    if (data.form_data && data.form_data.generate && data.form_data.generate === "match_report") {
                        this.setState({"doneModalMsg": "Match report generated", "tr_color": "success"});
                        this.showUsersNotification();
                    } else if (data.form_data && data.form_data.generate && data.form_data.generate === "webdosh_import") {
                        this.setState({"doneModalMsg": "Export Webdosh import file generated", "tr_color": "success"});
                        this.showUsersNotification();
                    }
                }
            })
            .catch((error) => {
                this.saving_form = false;
                try {
                    if (callback !== null) callback();
                } catch(e) {}
                helpers.hideLoading();
                this.processErrorAxios(
                    error,
                    null
                );
            });
    }

    handleResetPassword() {
        const client_selected = this.state.clients.find((o) => o.id === this.state.id).data;
        if (!client_selected.mailable && !client_selected.mobile_phone) {
            this.setState({
                "doneModalMsg": "Please add an email address or a mobile phone number to this client's contact.",
                "tr_color": "danger"
            });
            this.showUsersNotification();
            return ;
        }

        this.setState({
            "confirmModal": true,
            "confirmModalMsg": `Are you sure you want to continue?`,
            "confirmModalTitle": "Reset Password",
            "sub_action": "reset_client_password",
        });
    }

    doHandleResetPassword() {
        this.handleClose("confirmModal");
        const url = "/client/" + this.state.id + '/reset/password';
        helpers.showLoading();
        return axios(this.props)
            .post(url, {}, {"headers": {"Content-Type": "application/x-www-form-urlencoded"}})
            .then((response) => {
                this.setState({
                    "sub_action": "",
                    "doneModalMsg": "Client password reset",
                    "tr_color": "success"
                });
                this.showUsersNotification();
                helpers.hideLoading();
            })
            .catch((error) => {
                this.processErrorAxios(
                    error,
                    null
                );
            });
    }

    handleUpdateWatch(event, element, status) {
        const url = "/client/" + this.state.client_selected.id + '/watch';
        helpers.showLoading();
        let fd = {'event': event, 'element': ""+element, 'status': status};
        return axios(this.props)
            .post(url, fd)
            .then((response) => {
                let client = this.state.client_selected;
                let clients = this.state.clients.map((prop, key) => {
                    if (client.id === prop.id) {
                        prop.watchedEvents = response.data.watchedEvents;
                        client.watchedEvents = response.data.watchedEvents;
                    }
                    return prop;
                });
                this.setState({
                    "clients": clients,
                    "client_selected": client
                });
                this.props.updateSelectedClient({...this.props}, client ? client.id : null);
                helpers.hideLoading();
            })
            .catch((error) => {
                this.saving_form = false;
                this.processErrorAxios(
                    error,
                    null
                );
            });
    }

    scrollToClientDetails() {
        setTimeout(
            () => {
                try {
                    this.props.scrollToOffset(this.clientFullDetailsRef.current.offsetTop);
                } catch(e) {}
            },
            500
        );
    }

    handleApplicationSignature = (event, file) => {
        event.preventDefault();
        if (file.signature_status === "PENDING") {
          this.sendApplicationSignatureRequest(file);
        } else if (file.signature_status === "NOT SUBMITTED") {
            this.setState({
                "confirmModal": true,
                "confirmModalMsg": "Are you sure?",
                "confirmModalTitle": "Request Signature",
                "action": "submit_for_signing",
                "approval_file": file
            });
        }
    };
  
    sendApplicationSignatureRequest(file) {
      helpers.showLoading();
      this.setState({"confirmModal": false});
      axios(this.props)
          .post(`client/${this.state.client_selected.id}/sign/application/${file.id}` + (file.signature_status === "NOT SUBMITTED" ? '' : '/status'))
          .then((response) => {
              helpers.hideLoading();
              if (file.signature_status === "NOT SUBMITTED") {
                  this.setState({
                      "doneModalMsg": response.data.status === "success" ? "Signature request submitted." : "An error has occurred, please try again.",
                      "tr_color": response.data.status === "success" ? "success" : "warning"
                  });
              } else {
                  this.setState({
                      "doneModalMsg": (response.data.status === "SIGNED" ? "Application already signed." : "Awaiting signature(s).") +
                                  (response.data.hasOwnProperty('error_msg') ? "<br>" + response.data.error_msg : ""),
                      "tr_color": response.data.status === "SIGNED" ? "success" : "warning"
                  });
              }
              this.showUsersNotification();
              
              this.refreshClientContactsTable(null, this.state.client_selected.id);
          })
          .catch((error) => this.processErrorAxios(
              error,
              null
          ));
    }

  render () {
      const authService = new AuthService(
          this.state,
          this.props
      );
        if (!AccessControl.checkRoutePermissions("/clients", this.props.auth)) {
            return <Redirect to="/admin/dashboard" />;
        }
      if (!authService.getToken()) {
          return <Redirect to="/auth/login" />;
      }

      if (authService.isPasswordExpired()) {
          return <Redirect to="/admin/changepassword" />;
      }
      const {classes} = this.props,
          {clients, page, pages, loading} = this.state;
      return (
          <GridContainer>
              <GridItem xs={12}>
                  <Card>
                      <CardHeader
                          color="success"
                          icon
                      >
                          <CardIcon color="success">
                              <AccountBox />
                          </CardIcon>
                          <h4 className={classes.cardIconTitle}>
                              Clients
                          </h4>
                            {AccessControl.checkRoutePermissions(
                                "/clients",
                                this.props.auth,
                                "add_client"
                            )
                              ? <CardIcon
                                title={'Create client'}
                                className="float-button"
                                color="info"
                                onClick={() => this.handleClientDetailsClick(
                                    "clientDetailsModal",
                                    null
                                )}
                              >
                                  <Add />
                                </CardIcon>
                              : " "}
                            <CardIcon
                                title={'Refresh clients table'}
                                className="float-button"
                                color="warning"
                                onClick={() => this.refreshClientsTable()}
                            >
                                <Refresh />
                            </CardIcon>

                            <CardIcon
                                title={(this.state.show_archived === 1 ? 'Hide' : 'Show') + ' archived clients'}
                                className="float-button"
                                color="success"
                                onClick={() => {
                                    if (!this.state.loading) {
                                        this.setState({"show_archived": this.state.show_archived === 1 ? 0 : 1});
                                        setTimeout(() => this.refreshClientsTable(), 100);
                                    }
                                }}
                            >
                                {
                                    <Icon className={"far fa-" + (this.state.show_archived === 1 ? "check-" : "") + "square"}
                                        style={{"width": "27px", "height": "27px", "margin": "1px 4px", "textAlign": "center", "lineHeight": "33px"}} />
                                }
                                <Icon className="fas fa-archive"
                                    style={{"width": "27px", "height": "27px", "margin": "1px 4px", "textAlign": "center", "lineHeight": "33px"}} />
                            </CardIcon>
                      </CardHeader>
                      <CardBody>
                          <ReactTable
                              filtered={this.state.filtered}
                              onFilteredChange={filtered => this.setState({ filtered })}
                              sorted={this.state.sorted}
                              onSortedChange={sorted => this.setState({ sorted })}
                              pageSize={this.state.pageSize}
                              onPageSizeChange={pageSize => this.setState({ pageSize })}

                              className="-striped -highlight"
                              columns={this.setColumns()}
                              data={clients}
                              defaultFilterMethod={(filter, row) => String(row[filter.id]) === filter.value}
                              defaultPageSize={this.state.pageSize}
                              filterable
                              getTdProps={(state, rowInfo, column, instance) => {
                                if (typeof rowInfo !== "undefined") {
                                    return {
                                        "onClick": (e, handleOriginal) => {
                                            this.setState({
                                                "selected": rowInfo.index
                                            });
                                            if (handleOriginal) {
                                                handleOriginal();
                                            }
                                            if (column.id !== 'actions') {
                                                this.handleClientLedgerClick(rowInfo.original.data);
                                            }
                                        },
                                        "style": {
                                            "cursor": column.id !== 'actions' ? "pointer" : ""
                                        }
                                    };
                                }
                                return {
                                    "onClick": (e, handleOriginal) => {
                                        if (handleOriginal) {
                                            handleOriginal();
                                        }
                                    }
                                };
                              }}
                              getTrProps={(state, rowInfo, column, instance) => {
                                  if (typeof rowInfo !== "undefined") {
                                      return {
                                          "style": {
                                              "height": this.state.client_read_only ? "61px" : " auto",
                                              "background":
                                                this.state.client_selected &&
                                                rowInfo.original &&
                                                rowInfo.original.id &&
                                                rowInfo.original.id === this.state.client_selected.id
                                                    ? (rowInfo.original.data.deleted_at === null ? "lightcyan" : "#ff9800")
                                                    : (rowInfo.original.data.deleted_at === null ? null : '#ffd69a')
                                          }
                                      };
                                  }
                                  return {};
                              }}
                              itemsCountText={this.state.items_data}
                              loading={loading}
                              manual
                              onFetchData={(state, instance) => {
                                  if (this.state.loading) return;
                                  this.setState({"loading": true});
                                  this.getClientsData(
                                      state.page,
                                      state.pageSize,
                                      state.sorted,
                                      state.filtered,
                                      (res) => this.processClientsData(res)
                                  );
                                  this.getFormOptions();

                              }}
                              page={page}
                              onPageChange={page => this.setState({page})}
                              pages={pages}
                              PaginationComponent={Pagination}
                              ref={(refReactClientsTable) => {

                                  this.refReactClientsTable = refReactClientsTable;

                              }}
                              showPageSizeOptions
                              showPaginationBottom
                              showPaginationTop={false}
                          />
                      </CardBody>
                  </Card>
              </GridItem>
              <div ref={this.clientFullDetailsRef} style={{width: '100%'}}>
                <ClientFullDetailsDialog
                    accessControlManager={this.accessControlManager}
                    auth={this.props.auth}
                    changeClientTabCallBack={this.changeClientTabCallBack}
                    getClientForms={this.handleViewForms}
                    getFormOptions={this.getFormOptions}
                    handleAddClientManager={this.handleAddClientManager}
                    handleAddForm={this.handleAddForm}
                    handleDeleteClientManager={this.handleDeleteClientManager}
                    handleDeleteContact={this.handleDeleteContact}
                    handleChangePrimaryContact={this.handleChangePrimaryContact}
                    handleDeleteFile={this.handleDeleteFile}
                    handleDeleteNote={this.handleDeleteNote}
                    handleDownloadContactDocument={this.handleDownloadContactDocument}
                    handleFormSubmit={this.handleFormSubmit}
                    handleFormSubmitForApproval={this.handleFormSubmitForApproval}
                    handleSaveContactDetails={this.handleSaveContactDetails}
                    handleSaveContactError={this.handleSaveContactError}
                    refreshClientContactsTable={this.refreshClientContactsTable}
                    setUsersNotification={this.setUsersNotification}
                    isReadOnly={this.state.client_read_only}
                    {...this.state}
                    handleUpdateStep={this.handleUpdateStep}
                    handleRemoveLibraryDocument={this.handleRemoveLibraryDocument}
                    handleAddDocuments={this.handleAddDocuments}
                    generateScriptDialog={this.generateScriptDialog}
                    handleUpdateWatch={this.handleUpdateWatch}
                    handleApplicationSignature={this.handleApplicationSignature}
                />
              </div>
              {this.state.clientDetailsModal
                ? <ClientDetailsDialog
                    accessControlManager={this.accessControlManager}
                    handleChange={this.handleChangeByName}
                    handleManualChange={this.handleManualChange}
                    handleMultiple={this.handleMultiple}
                    handleTransactionMethods={this.handleTransactionMethods}
                    handleSelectManagerTeam={this.handleSelectManagerTeam}
                    handleSelectLead={this.handleSelectLead}
                    handleSelectPrimaryContact={this.handleSelectPrimaryContact}
                    handleSelectMerchantType={this.handleSelectMerchantType}
                    handleSelectMCCCode={this.handleSelectMCCCode}
                    onClose={() => this.handleClose("clientDetailsModal")}
                    onYesClick={(contact, contact_documents) => this.handleAcceptClientDetails("confirmModal", contact, contact_documents)}
                    handleResetPassword={this.handleResetPassword}
                    selected_contact={[]}
                    countries={this.state.countries}
                    {...this.state}
                    auth={this.props.auth}
                />
                : ""
              }
              <AddManagerToClientDialog
                  handleAddManagerFromAddDialog={this.handleAddManagerFromAddDialog}
                  handleAddNewManager={this.handleAddNewManager}
                  onClose={() => this.handleClose("selectManagerModal")}
                  {...this.state}
              />
              <SelectScriptTemplates
                  showDuplicateScriptDialog={this.showDuplicateScriptDialog}
                  handleGenerateScript={this.handleGenerateScript}
                  onChange={(templates) => this.setState({'selected_templates': templates})}
                  onClose={() => {
                      this.setState({'selected_templates': []});
                      this.handleClose("selectScriptTemplateModal");
                  }}
                  templates={this.state.selected_templates}
                  {...this.state}
                  showDuplicateButton={true}
              />
              <DuplicateClientScripts
                  handleDuplicateScripts={this.handleDuplicateScripts}
                  onChange={(client_id) => this.handleDuplicateScriptClientSelect(client_id)}
                  onMultipleChange={(templates) => this.setState({'selected_templates': templates})}
                  onClose={() => {
                      this.setState({'selected_templates': []});
                      this.handleClose("duplicateClientScriptsModal");
                  }}
                {...this.state}
              />
              <UserDetailsDialog
                  dialogTitle="New Team Leader"
                  handleChange={this.handleChange}
                  handleMultiple={null}
                  onClose={() => this.handleClose("userDetailsModal")}
                  onYesClick={() => this.handleAcceptAddNewManager()}
                  showRoles={false}
                  {...this.state}
              />
              <ConfirmDialog
                  confirmModal={this.state.confirmModal}
                  confirmModalMsg={this.state.confirmModalMsg}
                  confirmModalTitle={this.state.confirmModalTitle}
                  onClose={() => this.handleClose("confirmModal")}
                  onYesClick={() => this.handleConfirmClick()}
              />
              {this.state.doneModalMsg
                  ? <Snackbar
                      close
                      closeNotification={() => {

                          this.handleCloseNotification();

                      }}
                      color={this.state.tr_color}
                      icon={AddAlert}
                      message={this.state.doneModalMsg}
                      open={this.state.tr}
                      place="tr"
                  />
                  : ""}
          </GridContainer>
      );
  }
}

Clients.propTypes = {
    "classes": PropTypes.object
};

const mapStateToProps = (state, props) => ({
        "auth": state.auth.data
    }),

    mapDispatchToProps = (dispatch) => ({
        "doLogout": (props) => dispatch(doLogout(props)),
        updateSelectedClient: (props, client_selected) => {
            dispatch({ type: "updateSelectedClient", props, selectedClient: client_selected });
        }
    });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(extendedFormsStyle)(Clients));
