import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import validations from "customs/helpers/validations";
import helpers from "customs/helpers/helpers";
import axios from "axios/axios";

import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";

// Core components
import Button from "components/CustomButtons/Button.jsx";

class AsicConfirmWithInputDialog extends React.Component {

    constructor (props) {

        super(props);

        this.state = {
            "message": props.message,
            "accnt_details": [{'accnt_number': '', 'business_name': '', 'status': false}],
        };
        this.addABNACNEntry = this.addABNACNEntry.bind(this);
        this.removeABNACNEntry = this.removeABNACNEntry.bind(this);
        this.onABNACNChange = this.onABNACNChange.bind(this);
        this.getABNACNValue = this.getABNACNValue.bind(this);
        this.checkABNACNEntry = this.checkABNACNEntry.bind(this);
    }

    addABNACNEntry() {
        let accnt_details = this.state.accnt_details;
        accnt_details.push({'accnt_number': '', 'business_name': '', 'status': false});

        this.setState({'accnt_details': accnt_details});
    }

    removeABNACNEntry(idx) {
        let accnt_details = this.state.accnt_details;
        delete accnt_details[idx];

        this.setState({'accnt_details': accnt_details}, function() {
            let accnt_details = this.state.accnt_details;
            if (Object.keys(accnt_details).length === 0) this.setState({'accnt_details': [{'accnt_number': '', 'business_name': '', 'status': false}]});
        });
    }

    onABNACNChange(event, index) {
        let findStr = event.target.value.replace(/[^\d]/g, '');
        let value = validations.validateACN(findStr) ? helpers.acnFormat(findStr) : helpers.abnFormat(findStr);
        let accnt_details = this.state.accnt_details;
        accnt_details[index].accnt_number = value;
        accnt_details[index].status = false;
        this.setState({'accnt_details': accnt_details});
    }

    getABNACNValue(index, target) {
        return this.state.accnt_details && this.state.accnt_details[index] && this.state.accnt_details[index][target] ? this.state.accnt_details[index][target] : '';
    }

    checkABNACNEntry(index) {
        let accnt = this.getABNACNValue(index, 'accnt_number');
        if (!accnt || accnt.length === 0) {
            return;
        }
        if (!validations.validateABN(accnt) && !validations.validateACN(accnt)) {
            this.props.setUsersNotification("The ABN/ACN " + accnt + " is invalid.", "danger");
            return;
        }

        helpers.showLoading();
        let findStr = accnt.replace(/[^\d]/g, '');
        let searchBy = validations.validateABN(findStr) ? 'abn' : (validations.validateACN(findStr) ? 'acn' : false);
        axios(this.props)
            .get(`/${searchBy}/${accnt.replace(/[^\d]/g, '')}`)
            .then((response) => {
                response = response.data;
                let business_name = "";
                if (
                    response.hasOwnProperty('businessEntity') &&
                    response.businessEntity.hasOwnProperty('mainName') &&
                    response.businessEntity.mainName.hasOwnProperty('organisationName')
                ) {
                    business_name = response['businessEntity']['mainName']['organisationName'];
                } else {
                    let name = [];
                    if (
                        response.hasOwnProperty('businessEntity') &&
                        response.businessEntity.hasOwnProperty('legalName') &&
                        response.businessEntity.legalName.hasOwnProperty('familyName')
                    ) {
                        name.push(response['businessEntity']['legalName']['familyName']);
                    }
                    if (
                        response.hasOwnProperty('businessEntity') &&
                        response.businessEntity.hasOwnProperty('legalName') &&
                        response.businessEntity.legalName.hasOwnProperty('givenName')
                    ) {
                        name.push(response['businessEntity']['legalName']['givenName']);
                    } else if (
                        response.hasOwnProperty('businessEntity') &&
                        response.businessEntity.hasOwnProperty('legalName') &&
                        response.businessEntity.legalName.hasOwnProperty('otherGivenName')
                    ) {
                        name.push(response['businessEntity']['legalName']['otherGivenName']);
                    }
                    business_name = name.join(' ');
                }
                let accnt_details = this.state.accnt_details;
                accnt_details[index].business_name = business_name;
                accnt_details[index].status = true;
                this.setState({'accnt_details': accnt_details});
                helpers.hideLoading();
            })
            .catch((error) => {
                let accnt_details = this.state.accnt_details;
                accnt_details[index].business_name = '';
                accnt_details[index].status = false;
                this.setState({'accnt_details': accnt_details});

                helpers.hideLoading()
            });
    }

    componentWillReceiveProps(props) {
        if (props.message) {
            this.setState({'message': props.message});
        }
    }

    render () {

        const {classes} = this.props;
        return (
            <Dialog
                aria-describedby="small-modal-slide-description"
                aria-labelledby="small-modal-slide-title"
                classes={{
                    "root": `${classes.center} ${classes.modalRoot}`,
                    "paper": `${classes.modal} ${classes.modalSmall}`
                }}
                keepMounted
                maxWidth={this.props.minWidth ? "lg" : "sm"}
                onClose={() => this.props.onClose("confirmWithInputModal")}
                open={this.props.confirmWithInputModal}
                style={{"zIndex": 9999}}
            >
                <DialogTitle
                    className={`${classes.modalBody} ${classes.modalSmallBody}`}
                    style={{"paddingBottom": "0px"}}
                >
                    {this.props.confirmModalTitle
                        ? this.props.confirmModalTitle
                        : "Confirm"}
                </DialogTitle>
                <DialogContent
                    className={`${classes.modalBody} ${classes.modalSmallBody}`}
                    id="small-modal-slide-description"
                    style={{overflowX: "hidden"}}
                >
                    <h5 style={{"minWidth": this.props.minWidth ? this.props.minWidth : "200px",
                        "marginBottom": "1rem"}}
                    >
                        {this.props.confirmModalMsg
                            ? this.props.confirmModalMsg
                            : "Are you sure?"}
                    </h5>

                    <table style={{width: "100%"}}>
                        <tbody>
                            {
                                this.state.accnt_details.map((v, k) => {
                                    return <tr key={k}>
                                        <td style={{width: "50%"}}>
                                            <CustomInput
                                                formControlProps={{"fullWidth": true}}
                                                inputProps={{
                                                    "onChange": (event) => this.onABNACNChange(event, k),
                                                    "value": this.getABNACNValue(k, 'accnt_number'),
                                                    "name": "accnt_number_" + k,
                                                    "disabled": false
                                                }}
                                                labelText="ABN/ACN"
                                            />
                                        </td>
                                        <td>
                                            <CustomInput
                                                formControlProps={{"fullWidth": true}}
                                                inputProps={{
                                                    "value": this.getABNACNValue(k, 'business_name'),
                                                    "name": "business_name_" + k,
                                                    "disabled": true
                                                }}
                                                labelText="Business Name"
                                            />
                                        </td>
                                        <td style={{width: "5%", textAlign: "right", whiteSpace: "nowrap"}}>
                                            <span style={{cursor: "pointer", paddingRight: "5px"}}
                                                onClick={(event) => this.checkABNACNEntry(k)}
                                            >
                                                <i title={"Search ABN/ACN"} className={"fas fa-search"} style={{fontSize: "0.9rem", marginRight: "0.1rem"}}/>
                                            </span>
                                            <span style={{cursor: "pointer"}}
                                                onClick={(event) => this.removeABNACNEntry(k)}
                                            >
                                                <i title={"Remove ABN/ACN"} className={"fa fa-minus"} style={{"color": "red", fontSize: "0.9rem", marginRight: "0.1rem"}}/>
                                            </span>
                                        </td>
                                    </tr>;
                                })
                            }
                            <tr>
                                <td colSpan={3} style={{textAlign: "right"}}>
                                    <span style={{cursor: "pointer"}}
                                        onClick={(event) => this.addABNACNEntry()} >
                                        <i title={"Add ABN/ACN"} className={"fas fa-plus"} style={{"color": "green", fontSize: "0.9rem", marginRight: "0.1rem"}}/>
                                        {/* <span>{"Add ABN/ACN"}</span> */}
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <InputLabel
                        style={{ paddingTop : "1.5rem" }}
                        className={classes.selectLabel}
                        htmlFor="message"
                    >
                        {this.props.hasOwnProperty('messageLabel') ? this.props.messageLabel : 'Message'}
                    </InputLabel>
                    <TextField
                        style={{overflowX: "hidden"}}
                        inputProps={{
                            name: `message`,
                            id: `message`
                        }}
                        type="text"
                        className="w-100"
                        value={this.state.message ? this.state.message : ""}
                        multiline={true}
                        rows={this.props.rows ? this.props.rows : 3}
                        onChange={(event) => {
                            this.setState({"message": event.target.value});
                        }}
                    />
                </DialogContent>
                <DialogActions
                    className={`${classes.modalFooter} ${classes.modalFooterCenter}`}
                >
                    <Button
                        className={classes.modalSmallFooterFirstButton}
                        color="transparent"
                        onClick={() => this.props.onClose("confirmWithInputModal")}
                    >
                        {this.props.noLbl ? this.props.noLbl : "No"}
                    </Button>
                    <Button
                        className={
                            `${classes.modalSmallFooterFirstButton
                            } ${
                                classes.modalSmallFooterSecondButton}`
                        }
                        color="success"
                        onClick={
                            () => {
                                let has_empty = false;
                                let has_fail = false;
                                this.state.accnt_details.forEach((accnt) => {
                                    if (!accnt.accnt_number || accnt.accnt_number.length === 0) has_empty = true;
                                    if (!accnt.status) has_fail = true;
                                });
                                if (has_empty) {
                                    this.props.setUsersNotification("Please fill-in the empty ABN/ACN field.", "danger");
                                    return;
                                }
                                if (has_fail) {
                                    this.props.setUsersNotification("One or more ABN/ACN has not been searched, please use the search button to the right of the ABN/ACN field.", "danger");
                                    return;
                                }

                                if ((!this.state.message.length || this.state.message.length === 0) && this.props.hasOwnProperty('messageOptional') && this.props.messageOptional !== true) {
                                    this.props.setUsersNotification((this.props.hasOwnProperty('messageLabel') ? this.props.messageLabel : 'Message') + " is required.", "danger");
                                } else {
                                    this.props.onYesClick(this.state.message, this.state.accnt_details);
                                }
                            }
                        }
                        simple
                    >
                        {this.props.yesLbl ? this.props.yesLbl : "Yes"}
                    </Button>
                </DialogActions>
            </Dialog>
        );

    }

}

export default withStyles(userProfileStyles)(AsicConfirmWithInputDialog);
